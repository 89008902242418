import { notification } from 'antd';

export function newBid(data) {
    notification.open({
        message: data.message,
        description: data.bidAmount,
        key: data.bidid,
        duration: 0,
        type: "error"
    });
};