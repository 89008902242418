import { io } from 'socket.io-client';
import util from '../utils/util';
import { newBid } from './meeting';
import config from '../config';

const socket = io.connect("https://api.carenzy.com", {
    auth: (cb) => {
        cb({
            token: 'Bearer ' + util.getToken()
        });
    }
});

socket.emit("subscribe", ["652ccbc9966e4f317fa51566"]);

socket.on("new-bid", (data) => {
    newBid(data);
});

// setTimeout(() => {
//     socket.emit("unsubscribe", ["6516b67e653caf2b21efd932"]);
// }, 20 * 1000);